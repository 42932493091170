import store from "@/state/store";

let beforeResolve = function(routeTo, routeFrom, next) {
  // If the user is already logged in
  if (store.getters["auth/loggedIn"]) {
    // Redirect to the dashboard page instead
      next({ name: "dashboard" });
  } else {
    next();
  }
}

export default [
  {
    path: "/",
    name: "default",
    meta: { title: "Dashboard", authRequired: true },
    component: () => import("../pages/dashboard.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: { title: "Forgot Password", authRequired: false },
    component: () => import("../pages/forgot-password.vue"),
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    meta: { title: "Reset Password", authRequired: false },
    component: () => import("../pages/reset-password.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Log in",
      authRequired: false,
      beforeResolve,
    },
    component: () => import("../pages/login.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    meta: {
      title: "Registration",
      authRequired: false,
      beforeResolve,
    },
    // component: () => import("../pages/coming-soon.vue"),
    component: () => import("../pages/registration.vue"),
  },
  {
    path: "/registration-success",
    name: "registration-success",
    meta: {
      title: "Registration success",
      authRequired: false,
      beforeResolve,
    },
    // component: () => import("../pages/coming-soon.vue"),
    component: () => import("../pages/registration-success.vue"),
  },
  {
    path: "/phone-verification",
    name: "phone-verification",
    meta: {
      title: "Phone verification",
      authRequired: false,
      beforeResolve,
    },
    component: () => import("../pages/phone-verification.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { title: "Dashboard", authRequired: true },
    component: () => import("../pages/dashboard.vue"),
  },
  {
    path: "/deals",
    name: "Deals",
    meta: { title: "Deals", authRequired: true },
    component: () => import("../pages/deals.vue"),
    props: route => ({ status: route.query.status })
  },
  {
    path: "/documents",
    name: "Documents",
    meta: { title: "Documents", authRequired: true },
    component: () => import("../pages/documents.vue"),
  },
  {
    path: "/faqs",
    name: "FAQs",
    meta: { title: "FAQs", authRequired: true },
    component: () => import("../pages/faqs.vue"),
  },
  {
    path: "/api-keys",
    name: "API keys",
    meta: { title: "Api keys", authRequired: true },
    component: () => import("../pages/api-keys.vue"),
  },
  {
    path: "/bots",
    name: "Bots",
    meta: { title: "Bots", authRequired: true },
    component: () => import("../pages/bots.vue"),
  },
  {
    path: "/create-bot",
    name: "Create Bot",
    meta: { title: "Create bot", authRequired: true },
    component: () => import("../pages/create-bot.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { title: "Profile", authRequired: true },
    component: () => import("../pages/profile.vue"),
  },
  {
    path: "/profile/referral-program",
    name: "Referral program",
    meta: { title: "Referral program", authRequired: true },
    component: () => import("../pages/referral-program.vue"),
  },
  {
    path: "/subscription-plan",
    name: "Subscription plan",
    meta: { title: "Subscribe plan", authRequired: true },
    component: () => import("../pages/subscribe.vue"),
  },
  {
    path: "/subscription-plan/payment-completed",
    name: "Payment completed",
    meta: { title: "Payment completed", authRequired: true },
    component: () => import("../pages/subscribe.vue"),
  },
  {
    path: "/subscription-plan/payment-failed",
    name: "Payment failed",
    meta: { title: "Payment failed", authRequired: true },
    component: () => import("../pages/subscribe.vue"),
  },
  {
    path: "/profile/change-password",
    name: "Change password",
    meta: { title: "Change password", authRequired: true },
    component: () => import("../pages/change-password.vue"),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import("../pages/error-404.vue"),
  },
  {
    path: "/documents/binance/registration",
    name: "Registration on Binance",
    meta: { title: "Integration with Binance", authRequired: true },
    component: () => import("../pages/documentation/Binance/registration.vue"),
  },
  {
    path: "/documents/binance/how-to-buy-crypto",
    name: "Top up balance on Binance",
    meta: { title: "Integration with Binance", authRequired: true },
    component: () => import("../pages/documentation/Binance/how-to-buy-crypto.vue"),
  },
  {
    path: "/documents/bitget/integration-with-us",
    name: "integration-with-bitget-en",
    meta: { title: "Integration with Bitget", authRequired: true },
    component: () => import("../pages/documentation/Bitget/integration-with-us.vue"),
  },
  {
    path: "/documents/bitget/integration-with-us-ru",
    name: "integration-with-bitget-ru",
    meta: { title: "Integration with Bitget", authRequired: true },
    component: () => import("../pages/documentation/Bitget/integration-with-us-ru.vue"),
  },
  {
    path: "/documents/binance/integration-with-us",
    name: "integration-with-binance-en",
    meta: { title: "Integration with Binance", authRequired: true },
    component: () => import("../pages/documentation/Binance/integration-with-us.vue"),
  },
  {
    path: "/documents/binance/integration-with-us-ru",
    name: "integration-with-binance-ru",
    meta: { title: "Integration with Binance", authRequired: true },
    component: () => import("../pages/documentation/Binance/integration-with-us-ru.vue"),
  },
  // {
  //   path: "/release-notes",
  //   name: "Release notes",
  //   meta: { title: "Release notes", authRequired: true },
  //   component: () => import("../pages/release-notes/index.vue"),
  // },
  {
    path: "/portfolio-assets",
    name: "My portfolio",
    meta: { title: "Your assets", authRequired: true },
    component: () => import("../pages/portfolio-assets.vue"),
  },
  {
    path: "/futures-deals",
    name: "My futures deals",
    meta: { title: "Active active deals", authRequired: true },
    component: () => import("../pages/futures-deals.vue"),
  },
  {
    path: "/setup-wizard",
    name: "Setup Wizard",
    meta: { title: "Setup Wizard", authRequired: true },
    component: () => import("../pages/setup-wizard.vue"),
  },
  {
    path: "/auth/google/callback",
    component: () => import("../pages/auth-google.vue"),
  },
];
