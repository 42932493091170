import apiClient from "./http-common";

class UserService {
  userData() {
    return apiClient.get("/user-info");
  }
  registration(payload) {
    return apiClient.post("/registration", payload);
  }
  verifyPhone(code, userId) {
    return apiClient.post("/verify-phone", {code: code, user_id: userId});
  }
}

export default new UserService();
