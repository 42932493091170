import { createStore } from 'vuex'
import VuexORM from '@vuex-orm/core'
import axios from 'axios'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import User from './entities/user'
import modules from './modules'
import Bot from "@/state/entities/bot";
import Coin from "@/state/entities/coin";
import ExchangeConnection from "@/state/entities/exchangeConnection";
import Deal from "@/state/entities/deal";
import Exchange from "@/state/entities/exchange";

// Create a new instance of Database.
const database = new VuexORM.Database()

// Register Models to Database.
database.register(Bot)
database.register(User)
database.register(Coin)
database.register(ExchangeConnection)
database.register(Deal)
database.register(Exchange)


axios.interceptors.request.use(
    (config) => {
      config.headers['Content-type'] = 'application/json';
      let token = JSON.parse(localStorage.getItem('auth.access_token'));
      if (token) {
        config.headers['authorization'] = `Bearer ${ token }`;
      }
      return config;
    },
    async (error) => {
        return Promise.reject(error)
    }
);


const store = createStore({
    modules,
    // Enable strict mode in development to get a warning
    // when mutating state outside a mutation.
    // https://vuex.vuejs.org/guide/strict.html
    strict: process.env.NODE_ENV !== 'production',
    plugins: [VuexORM.install(database)]
})

VuexORM.use(VuexORMAxios, {
  axios,
  baseURL: process.env.VUE_APP_API_HOST
})

axios.interceptors.response.use((response) => {
    return response
}, async (error) => {
    if (error && error.response && error.response.status === 401) {
        await store.dispatch('auth/logOut')
    }
    return Promise.reject(error);
})

export default store

