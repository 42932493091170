import { Model } from '@vuex-orm/core'

export default class Exchange extends Model {
    static entity = 'exchanges'
    static primaryKey = 'key'

    static fields () {
        return {
            key: this.attr(''),
            status: this.attr(''),
            title: this.attr(''),
        }
    }
}
